import React from 'react';
import { Nav, Logo, NavLink, Bars, NavMenu, NavBtn } from './HeaderElements';
import JCLogo from '../../images/jc_logo.png';

const Header = ({ toggle }) => {
  return (
    <div className='Container'>
      <Nav>
        <Logo to='/'>
          <img src={JCLogo} alt='logo' />
        </Logo>
        <NavMenu>
          <NavLink className='menu-item' to='projects' smooth={true}>
            Projects
          </NavLink>
          <NavLink className='menu-item' to='about' smooth={true}>
            About
          </NavLink>
          <NavLink className='menu-item' to='contact' smooth={true}>
            Contact
          </NavLink>
        </NavMenu>
        <NavBtn>
          <a
            className='btn PrimaryBtn'
            href='https://www.linkedin.com/in/johnnyculbreth/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Resume
          </a>
        </NavBtn>
        <Bars onClick={toggle} />
      </Nav>
    </div>
  );
};

export default Header;
